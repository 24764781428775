import { Box, Chip, IconButton, LinearProgress } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { CustomModal } from '../common/CustomModal';
import { ToastAlert } from '../common/ToastAlert';
import { useForm } from '../../hooks/useForm';
import { getUsers, updateUser } from '../../services/api/users';
import { getApplications } from '../../services/api/applications';

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import CustomTransfer from '../common/CustomTransfer';
import UserForm from '../forms/User';
import { useUserAPI } from '../../context/userAPIContext';
import { customArrayFilterOperators } from '../../utils/customFilters';
import { customArraySortComparator } from '../../utils/customSorts';
import { CustomSearchSelect } from '../common/CustomSearchSelect';

const ShowAttributes = ({ user, attribute, applicationName, allowedAttributes }) => {
  const attributes = user[attribute];
  const applicationAttributes = attributes[applicationName];

  if (!applicationAttributes || applicationAttributes.length === 0) {
    return <p>N/A</p>;
  }

  const intersection = applicationAttributes.filter((x) => allowedAttributes.includes(x));

  return (
    <>
      {intersection.length > 0 ? (
        intersection.sort().map((userAttribute) => {
          return <Chip key={userAttribute} style={{ margin: '2px' }} label={userAttribute} />;
        })
      ) : (
        <p>N/A</p>
      )}
    </>
  );
};

const updateAllUsersModalProps = {
  title: 'Add permission to multiple users',
  description: 'Select Roles and Feature Toggles',
  confirmButtonText: 'Yes, Add Roles',
  cancelButtonText: 'No, cancel',
};

const editUserModalProps = {
  title: 'Edit User',
  description: 'Please, change the attributes that you need:',
  confirmButtonText: 'Save',
  cancelButtonText: 'Cancel',
  confirmButtonColor: 'primary',
  variant: 'outlined',
  confirmButtonIcon: <SaveIcon />,
};

const getAllAppRoles = (application) =>
  [].concat(application?.allowed_roles || [], application?.default_role || []);

const sliceIntoChunks = (array, chunkSize) => {
  const arrayOfChunks = [];
  for (let index = 0; index < array.length; index += chunkSize) {
    const chunk = array.slice(index, index + chunkSize);
    arrayOfChunks.push(chunk);
  }
  return arrayOfChunks;
};

const UserTabContent = () => {
  const userColumns = [
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 250,
      flex: 2,
    },
    {
      field: 'roles',
      headerName: 'Roles',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        const user = params.row;
        const allowedRoles = getAllAppRoles(selectedApplication);

        return (
          <div>
            <ShowAttributes
              user={user}
              attribute="roles"
              applicationName={selectedApplication.name}
              allowedAttributes={allowedRoles}
            />
          </div>
        );
      },
      filterOperators: customArrayFilterOperators(),
      sortComparator: customArraySortComparator,
      valueGetter: (params) => {
        const userRolesInSelectedApplication =
          params?.row?.roles?.[selectedApplication?.name] || [];
        const appRoles = getAllAppRoles(selectedApplication);
        const allowedUserRoles = userRolesInSelectedApplication.filter((role) =>
          appRoles.includes(role)
        );
        return allowedUserRoles || [];
      },
    },
    {
      field: 'feature_toggles',
      headerName: 'Feature Toggles',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        const user = params.row;
        const allowedFeatureToggles = selectedApplication.allowed_feature_toggles;
        return (
          <div>
            <ShowAttributes
              user={user}
              attribute="feature_toggles"
              applicationName={selectedApplication.name}
              allowedAttributes={allowedFeatureToggles}
            />
          </div>
        );
      },
      filterOperators: customArrayFilterOperators(),
      sortComparator: customArraySortComparator,
      valueGetter: (params) => {
        const userFeatureToggles = params?.row?.feature_toggles?.[selectedApplication?.name] || [];
        const appFeatureToggles = selectedApplication?.allowed_feature_toggles || [];
        const allowedFeatureToggles = userFeatureToggles.filter((featureToggle) =>
          appFeatureToggles?.includes(featureToggle)
        );
        return allowedFeatureToggles || [];
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 100,
      flex: 1,
      filterable: false,
      disableExport: true,
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => {
                // eslint-disable-next-line camelcase
                const { roles, feature_toggles, sub } = params.row;
                resetForm({
                  sub,
                  roles,
                  // eslint-disable-next-line camelcase
                  featureToggles: feature_toggles,
                });
                setOpenEditModal(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const defaultUser = {
    sub: '',
    roles: {},
    featureToggles: {},
  };

  const [users, setUsers] = useState([]);
  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedFeatureToggles, setSelectedFeatureToggles] = useState([]);
  const [openMultipleModal, setOpenMultipleModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [userFormValues, handleUsersInputChange, resetUser, resetForm] = useForm(defaultUser);
  const [messageToast, setMessageToast] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setLoading] = useState(true);
  const { checkUserAuthentication } = useUserAPI();
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [isLoadingMultipleModal, setIsLoadingMultipleModal] = useState(false);

  const handleChange = (event) => {
    setSelectedApplication(event.target.value);
  };

  const fetchUsers = async () => {
    const users = await getUsers();
    if (!users) return;
    setUsers(users);
    if (selectedUsers?.length) {
      setSelectedUsers(
        selectedUsers.map((selectedUser) => users.find((user) => user.sub === selectedUser.sub))
      );
    }
    setLoading(false);
  };

  const fetchApplications = async () => {
    const applications = await getApplications();
    if (!applications) return;
    if (applications.length > 0) {
      const defaultApplication = applications[0];
      setSelectedApplication(defaultApplication);
      setApplications(applications);
    }
  };

  useEffect(() => {
    checkUserAuthentication();
    fetchApplications();
    fetchUsers();
    // eslint-disable-next-line
  }, []);

  const updateSelectedUser = async () => {
    const result = await updateUser(userFormValues);
    resetUser();
    setOpenEditModal(false);
    setMessageToast(
      result
        ? { message: 'User successfully updated', severity: 'success' }
        : { message: 'Error on updating the User', severity: 'error' }
    );
    setOpenToast(true);
    await fetchUsers();
  };

  const updateUsersAttributes = async () => {
    const usersToUpdate = selectedUsers.map((selectedUser) => {
      const applicationName = selectedApplication.name;
      const currentUserRoles = [...(selectedUser.roles[applicationName] || [])];
      const currentFeatureToggles = [...(selectedUser?.feature_toggles[applicationName] || [])];
      selectedUser.roles[selectedApplication.name] = [
        ...new Set([...currentUserRoles, ...selectedRoles]),
      ];
      selectedUser.featureToggles = { ...selectedUser.feature_toggles };
      selectedUser.featureToggles[selectedApplication.name] = [
        ...new Set([...currentFeatureToggles, ...selectedFeatureToggles]),
      ];
      selectedUser.feature_toggles = selectedUser.featureToggles;
      return selectedUser;
    });

    setIsLoadingMultipleModal(true);

    const usersToUpdateInChunks = sliceIntoChunks(usersToUpdate, 10);
    for (const chunkOfUsersToUpdate of usersToUpdateInChunks) {
      const userPromises = chunkOfUsersToUpdate.map((userToUpdate) => updateUser(userToUpdate));
      await Promise.all(userPromises);
    }

    setIsLoadingMultipleModal(false);
    setOpenMultipleModal(false);
    setSelectedRoles([]);
    setSelectedFeatureToggles([]);
    setMessageToast({ message: 'Users successfully updated', severity: 'success' });
    setOpenToast(true);
    await fetchUsers();
  };

  useEffect(() => {
    if (selectedApplication) {
      setFilterModel({ items: [] });
    }
  }, [selectedApplication]);

  const isDisableMultipleModal = () =>
    isLoadingMultipleModal || (!selectedRoles.length && !selectedFeatureToggles.length);

  return (
    <>
      <Box sx={{ height: '100%', width: '100%' }}>
        <Box sx={{ marginY: 2 }}>
          <CustomSearchSelect
            options={applications}
            label="Applications"
            onChange={handleChange}
            notFoundMessage="No applications found"
            getDisplayValue={(option) => option.display_name}
            value={selectedApplication}
          />
        </Box>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p style={{ margin: '10px 0' }}>Hey, here you can manage users roles.</p>
          {selectedUsers.length > 0 && (
            <Button
              style={{ marginBottom: '10px' }}
              variant="contained"
              onClick={() => setOpenMultipleModal(true)}
              size="small"
            >
              Manage multiple roles and Feature Toggles
            </Button>
          )}
        </div>
        <div style={{ height: '50vh', width: '100%' }}>
          <div style={{ display: 'flex', height: '100%', justifyContent: 'center' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                rows={users}
                columns={userColumns}
                getRowId={(user) => user.sub}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 50]}
                pagination
                checkboxSelection
                filterModel={filterModel}
                onFilterModelChange={(model) => {
                  setFilterModel(model);
                }}
                loading={isLoading}
                disableSelectionOnClick
                components={{ Toolbar: GridToolbar, LoadingOverlay: LinearProgress }}
                onSelectionModelChange={(usersSelected) => {
                  const selectedGridUsers = users.filter((user) =>
                    usersSelected.includes(user.sub)
                  );
                  setSelectedUsers(selectedGridUsers);
                }}
                getRowHeight={() => 'auto'}
              />
            </div>
          </div>
        </div>
      </Box>

      <CustomModal
        {...updateAllUsersModalProps}
        open={openMultipleModal}
        handleClose={() => setOpenMultipleModal(false)}
        handleConfirm={updateUsersAttributes}
        handleCancel={() => setOpenMultipleModal(false)}
        isLoading={isLoadingMultipleModal}
        disabledConfirmButton={isDisableMultipleModal()}
      >
        <CustomTransfer
          selectedRoles={selectedRoles}
          setSelectedRoles={setSelectedRoles}
          selectedFeatureToggles={selectedFeatureToggles}
          setSelectedFeatureToggles={setSelectedFeatureToggles}
          allowedRoles={getAllAppRoles(selectedApplication)}
          allowedFeatureToggles={selectedApplication.allowed_feature_toggles}
        />
      </CustomModal>

      <CustomModal
        {...editUserModalProps}
        open={openEditModal}
        handleClose={() => setOpenEditModal(false)}
        handleConfirm={updateSelectedUser}
        handleCancel={() => setOpenEditModal(false)}
        disabledConfirmButton={false}
      >
        <UserForm
          {...userFormValues}
          applicationName={selectedApplication?.name}
          handleInputChange={handleUsersInputChange}
          allowedFeatureToggles={selectedApplication?.allowed_feature_toggles}
          allowedRoles={getAllAppRoles(selectedApplication)}
        />
      </CustomModal>

      <ToastAlert
        open={openToast}
        handleClose={() => setOpenToast(false)}
        message={messageToast.message}
        severity={messageToast.severity}
      />
    </>
  );
};

export default UserTabContent;
