import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { RandomReveal } from 'react-random-reveal';

import ioetWhiteLogo from '../../assets/images/ioet-white-logo.png';
import login1 from '../../assets/images/login-1.png';
import login2 from '../../assets/images/login-2.png';
import login3 from '../../assets/images/login-3.png';
import login4 from '../../assets/images/login-4.png';
import { APP_NAME } from '../../utils/constants';
import EnvManager from '../../config/envManager';

import './Login.css';

const loginURL = `${EnvManager.BACKEND_URL}/authn/login/${APP_NAME}`;
const imageDelay = 5000; // 5 seconds

const LoginButton = () => (
  <a className="login-btn" href={loginURL}>
    LOGIN
  </a>
);

const GradientOverlay = () => <div className="gradient-overlay display-none-mobile" />;

const RandomChars = ({ text }) => {
  const onComplete = () => {
    return {
      shouldRepeat: true,
      delay: Math.floor(imageDelay / 1000) - 2,
    };
  };
  return <RandomReveal isPlaying duration={2} characters={text} onComplete={onComplete} />;
};

const ImageGrid = ({ image, style }) => (
  <div
    style={{
      height: '100%',
      width: '100%',
      backgroundImage: `linear-gradient(270deg, #fff 0%, #fff0 40%), url("${image}")`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: '50%',
      ...style,
    }}
  />
);

const Login = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const [phraseIndex, setPhraseIndex] = useState(0);

  const loginImages = [login1, login2, login3, login4];
  const loginPhrases = [
    'your other home 🏠',
    'new adventures 🚀',
    'era of information 🤖',
    'best job ever 👩‍💻👨‍💻',
    'solve lots of bugs 🪲',
  ];

  const changeImageIndex = (milliseconds) => {
    setTimeout(() => {
      if (imageIndex === loginImages.length - 1) {
        setImageIndex(0);
      } else {
        setImageIndex(imageIndex + 1);
      }
      if (phraseIndex === loginPhrases.length - 1) {
        setPhraseIndex(0);
      } else {
        setPhraseIndex(phraseIndex + 1);
      }
    }, milliseconds);
  };

  useEffect(() => {
    changeImageIndex(imageDelay); // Change every 5 seconds
    // eslint-disable-next-line
  }, [imageIndex]);

  return (
    <Grid container style={{ position: 'relative', overflow: 'hidden', fontFamily: 'Barlow' }}>
      <GradientOverlay />
      <Grid item container sm={12} md={7} className="cover-height display-none-mobile">
        <TransitionGroup component={null}>
          <CSSTransition timeout={500} classNames="item" key={imageIndex}>
            <ImageGrid image={loginImages[imageIndex]} />
          </CSSTransition>
        </TransitionGroup>
      </Grid>
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="start"
        sm={12}
        md={5}
        className="cover-height-mobile"
        id="login-content"
      >
        <div>
          <img style={{ width: '35%' }} src={ioetWhiteLogo} alt="ioetlogo" />
        </div>
        <div
          style={{
            paddingBottom: '5%',
            lineHeight: '1%',
            color: '#fff',
            fontSize: '20px',
          }}
        >
          <h3 style={{ paddingBottom: '18px' }}>Welcome to...</h3>
          <span className="random-chars">
            <RandomChars text={loginPhrases[phraseIndex]} />
          </span>
        </div>
        <LoginButton />
      </Grid>
    </Grid>
  );
};

export default Login;
