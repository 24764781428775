import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard';
import { Admin } from './pages/Admin';
import { ADMIN_ROLE, ANY_ROLE } from './utils/constants';
import { UserAPIContextProvider, useUserAPI } from './context/userAPIContext';
import ThemeConfig from './theme';

import '@fontsource/barlow';
import { ThemeSettingsProvider } from './context/themeSettingsContext';

const ProtectedRoute = ({ element, role = ANY_ROLE }) => {
  const { user } = useUserAPI();
  if (user && (role === ANY_ROLE || user.roles.authService.includes(role))) {
    return element;
  }
  return <Navigate to="/login" replace />;
};

const LoginRoute = () => {
  const { user } = useUserAPI();
  if (!user) return <Login />;
  return <Navigate to="/dashboard" />;
};

const App = () => {
  return (
    <ThemeSettingsProvider>
      <ThemeConfig>
        <UserAPIContextProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="*" element={<Navigate to="/login" />} />
              <Route path="/login" element={<LoginRoute />} />
              <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
              <Route
                path="/admin-tools"
                element={<ProtectedRoute element={<Admin />} role={ADMIN_ROLE} />}
              />
            </Routes>
          </Router>
        </UserAPIContextProvider>
      </ThemeConfig>
    </ThemeSettingsProvider>
  );
};

export default App;
