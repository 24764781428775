/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { getApplications } from '../services/api/applications';
import AuthPageLayout from '../layout/AuthPageLayout';
import Card from '../components/common/Card';
import LoadingIndicator from '../components/common/LoadingSpinner';
import { useUserAPI } from '../context/userAPIContext';
import { NO_ACCESS_ROLE, APP_NAME } from '../utils/constants';

export default function Dashboard() {
  const [applications, setApplications] = useState(null);
  const [applicationsToShow, setApplicationsToShow] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const { checkUserAuthentication, user } = useUserAPI();

  useEffect(() => {
    const hasNoAccessRole = (roles) => roles.some((role) => role.trim() === NO_ACCESS_ROLE);

    const AppNamesToHide = Object.entries(user?.roles || []).reduce((acc, [app_name, roles]) => {
      if (hasNoAccessRole(roles)) {
        acc.push(app_name);
      }
      return acc;
    }, []);

    const allowedAppRoles = (app) => [].concat(app?.allowed_roles, app?.default_role);
    const allowedApplications = applications?.filter(
      (app) =>
        (!AppNamesToHide.includes(app?.name) || !hasNoAccessRole(allowedAppRoles(app))) &&
        app?.name !== APP_NAME
    );

    setApplicationsToShow(allowedApplications);
  }, [applications, user?.roles]);

  const fetchApplications = async () => {
    const applications = await getApplications();
    if (!applications) return;
    setApplications(applications);
    setLoading(false);
  };

  useEffect(() => {
    checkUserAuthentication();
    fetchApplications();
    // eslint-disable-next-line
  }, []);

  return (
    <AuthPageLayout containerWidth="lg">
      <Grid container direction="column">
        <Paper
          sx={{
            p: 5,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}
        >
          <Typography variant="h6" sx={{ paddingBottom: 1, fontWeight: 'bold' }}>
            Internal applications
          </Typography>
          <Typography variant="h7" sx={{ paddingBottom: 4 }}>
            Click the application you want to go to
          </Typography>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <>
              <Grid container direction="row" justifyContent="space-around" item spacing={2}>
                {applicationsToShow?.map((item, index) => (
                  <Card
                    key={index}
                    source={item.icon}
                    tooltip={item.url}
                    alt={item.name}
                    url={item.url}
                    display_name={item.display_name}
                  />
                ))}
              </Grid>
            </>
          )}
        </Paper>
      </Grid>
    </AuthPageLayout>
  );
}
