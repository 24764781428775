import axios from 'axios';
import EnvManager from '../../config/envManager';

const backend = axios.create({
  baseURL: EnvManager.BACKEND_URL,
  withCredentials: true,
});

export const logoutUser = async () => {
  try {
    await backend.get('/authn/logout');
  } catch (error) {
    return null;
  }
};
