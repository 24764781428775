export const customArraySortComparator = (firstArray, nextArrayToCompare) => {
  if (!firstArray.length || !nextArrayToCompare.length)
    return nextArrayToCompare.length - firstArray.length;

  firstArray.sort();
  nextArrayToCompare.sort();

  if (firstArray[0] > nextArrayToCompare[0]) return 1;
  if (firstArray[0] < nextArrayToCompare[0]) return -1;

  return 0;
};
