import { createContext, useState, useEffect } from 'react';

const initialState = {
  themeMode: 'light',
  onChangeMode: () => {},
};

const ThemeSettingsContext = createContext(initialState);
const storeThemeModeKey = 'authThemeMode';

function ThemeSettingsProvider({ children }) {
  const getThemeMode = () => localStorage.getItem(storeThemeModeKey);

  if (!getThemeMode()) {
    localStorage.setItem(storeThemeModeKey, initialState.themeMode);
  }

  const [themeMode, setThemeMode] = useState(getThemeMode);

  const onChangeMode = () => {
    const newThemeMode = themeMode === 'light' ? 'dark' : 'light';
    localStorage.setItem(storeThemeModeKey, newThemeMode);
    setThemeMode(newThemeMode);
  };

  useEffect(() => {
    const listener = (e) => {
      if (e.storageArea === localStorage && e.key === storeThemeModeKey) {
        setThemeMode(e.newValue);
      }
    };
    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, []);

  return (
    <ThemeSettingsContext.Provider value={{ themeMode, onChangeMode }}>
      {children}
    </ThemeSettingsContext.Provider>
  );
}

export { ThemeSettingsProvider, ThemeSettingsContext };
