import React from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import AppsIcon from '@mui/icons-material/Apps';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AppBarDrawerCombo from '../components/common/AppBarDrawerCombo';
import { ADMIN_ROLE, ANY_ROLE } from '../utils/constants';
import { useUserAPI } from '../context/userAPIContext';

const AuthPageLayout = (props) => {
  const navigate = useNavigate();
  const { user } = useUserAPI();

  const sideBarMenuItems = [
    {
      text: 'Applications',
      icon: AppsIcon,
      onClick: () => navigate('/dashboard'),
      role: ANY_ROLE,
    },
    {
      text: 'Admin Tools',
      icon: SupervisorAccountIcon,
      onClick: () => navigate('/admin-tools'),
      role: ADMIN_ROLE,
    },
  ];

  const getMenuItemsAccordingToUserRole = () =>
    sideBarMenuItems.filter(
      (item) =>
        item.role === ANY_ROLE ||
        (item.role !== ANY_ROLE && user.roles.authService.includes(item.role))
    );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBarDrawerCombo listItems={getMenuItemsAccordingToUserRole()} drawerWidth={240} />
      <Box
        component="main"
        sx={{
          backgroundColor: 'background.layout',
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth={props.containerWidth || 'md'} sx={{ mt: 4, mb: 4 }}>
          {props.children}
        </Container>
      </Box>
    </Box>
  );
};

export default AuthPageLayout;
