import { useState } from 'react';
import NavBar from '../common/AppBar';
import Sidebar from '../common/SideBar';

const AppBarDrawerCombo = (props) => {
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <NavBar position="fixed" open={open} toggleDrawer={toggleDrawer} />
      <Sidebar open={open} listItems={props.listItems} toggleDrawer={toggleDrawer} />
    </>
  );
};

export default AppBarDrawerCombo;
