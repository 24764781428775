/* eslint-disable camelcase */
import { Box } from '@mui/material';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { isAlphanumeric, isURL } from 'validator';
import { CustomAutoComplete } from '../common/CustomAutoComplete';

const ApplicationForm = ({
  name,
  displayName,
  defaultRole,
  url,
  icon,
  roles,
  feature_toggles,
  handleInputChange,
}) => {
  const [isNameValid, setIsNameValid] = useState(true);
  const [isInputRoleValid, setIsInputRoleValid] = useState(true);
  const [isURLValid, setIsURLValid] = useState(true);
  const [isIconURLValid, setIsIconURLValid] = useState(true);
  const [timer, setTimer] = useState(null);

  const handleDefaultRoleInputChange = (event) => {
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      if (roles?.includes(event.target.value)) {
        roles.splice(roles.indexOf(event.target.value), 1);
        setIsInputRoleValid(true);
        // Update allowed roles field on form
        handleInputChange(event);
      }
    }, 300);

    handleInputChange(event);
    setTimer(newTimer);
  };

  return (
    <div style={{ margin: '20px 0' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          item
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid container item spacing={2} direction="row">
            <Grid item xs={12}>
              <Box display="flex">
                <TextField
                  fullWidth
                  label="Name:"
                  placeholder="Enter name without spaces"
                  required
                  onChange={(event) => {
                    setIsNameValid(isAlphanumeric(event.target.value));
                    handleInputChange(event);
                  }}
                  error={!isNameValid}
                  helperText={!isNameValid && 'Enter a valid name'}
                  name="name"
                  value={name}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container item spacing={2} direction="row">
            <Grid item xs={12}>
              <Box display="flex">
                <TextField
                  fullWidth
                  label="Display Name:"
                  required
                  onChange={handleInputChange}
                  name="displayName"
                  value={displayName}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container item spacing={2} direction="row">
            <Grid item xs={12}>
              <Box display="flex">
                <TextField
                  fullWidth
                  label="Url:"
                  placeholder="e.g. https://example.ioet.com"
                  required
                  onChange={(event) => {
                    setIsURLValid(isURL(event.target.value, { require_tld: false }));
                    handleInputChange(event);
                  }}
                  error={!isURLValid}
                  helperText={!isURLValid && 'Enter a valid URL'}
                  name="url"
                  value={url}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container item spacing={2} direction="row">
            <Grid item xs={12}>
              <Box display="flex">
                <TextField
                  fullWidth
                  label="Icon url:"
                  placeholder="e.g. https://example.com/image.png"
                  required
                  onChange={(event) => {
                    setIsIconURLValid(isURL(event.target.value, { require_tld: false }));
                    handleInputChange(event);
                  }}
                  error={!isIconURLValid}
                  helperText={!isIconURLValid && 'Enter a valid URL for the icon'}
                  name="icon"
                  value={icon}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container item spacing={2} direction="row">
            <Grid item xs={12}>
              <Box display="flex">
                <TextField
                  fullWidth
                  label="Default role:"
                  required
                  onChange={(event) => {
                    handleDefaultRoleInputChange(event);
                  }}
                  name="defaultRole"
                  value={defaultRole}
                  helperText={
                    !defaultRole &&
                    "Use 'no-access' if you want to hide the application from all users by default."
                  }
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container item spacing={2} direction="row">
            <Grid item xs={12}>
              <Box display="flex">
                <CustomAutoComplete
                  value={roles}
                  label="Roles"
                  isFreeSolo={true}
                  onChange={(_, values) => {
                    const hasDefaultRoleInValues = values?.includes(defaultRole) || false;
                    setIsInputRoleValid(!hasDefaultRoleInValues);
                    if (!hasDefaultRoleInValues) {
                      handleInputChange({
                        target: { name: 'roles', value: values },
                      });
                    }
                  }}
                  warning={!isInputRoleValid}
                  helperText={
                    !isInputRoleValid && 'The input role should be different from the default role'
                  }
                  placeholder="Press Enter to add a Role"
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container item spacing={2} direction="row">
            <Grid item xs={12}>
              <Box display="flex">
                <CustomAutoComplete
                  value={feature_toggles}
                  label="Feature Toggles"
                  isFreeSolo={true}
                  onChange={(_, values) => {
                    handleInputChange({
                      target: { name: 'feature_toggles', value: values },
                    });
                  }}
                  placeholder="Press Enter to add a Feature toggle"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ApplicationForm;
