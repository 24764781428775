import Select from '@mui/material/Select';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { useState, useMemo } from 'react';

export const CustomSearchSelect = ({
  value,
  onChange,
  options = [],
  label,
  placeholder = 'Type to search...',
  notFoundMessage = 'No results found',
  getDisplayValue = (option) => option,
}) => {
  const [searchApplicationText, setSearchApplicationText] = useState('');

  const containsText = (text, searchText) =>
    text?.toLowerCase()?.indexOf(searchText?.toLowerCase()) > -1;

  const displayedOptions = useMemo(
    () => options?.filter((option) => containsText(getDisplayValue(option), searchApplicationText)),
    [searchApplicationText, options, getDisplayValue]
  );

  return (
    <FormControl fullWidth>
      <InputLabel id="search-select-label">{label}</InputLabel>
      <Select
        labelId="search-select-label"
        id="search-select"
        value={value}
        onChange={onChange}
        label={label}
        MenuProps={{
          autoFocus: false,
          PaperProps: {
            sx: {
              maxHeight: {
                sm: 'calc(40% - 96px)',
                xs: 'calc(100% - 96px)',
              },
            },
          },
        }}
        renderValue={() => getDisplayValue(value)}
        onClose={() => setSearchApplicationText('')}
      >
        <ListSubheader>
          <TextField
            size="small"
            autoFocus
            placeholder={placeholder}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchApplicationText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        {displayedOptions.length ? (
          displayedOptions.map((option, index) => (
            <MenuItem key={index} value={option}>
              {getDisplayValue(option)}
            </MenuItem>
          ))
        ) : (
          <MenuItem value={null} disabled>
            {notFoundMessage}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};
