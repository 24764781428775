import { Box } from '@mui/material';
import { CustomAutoComplete } from '../common/CustomAutoComplete';
import Grid from '@mui/material/Grid';

const UserForm = ({
  featureToggles,
  roles,
  allowedRoles,
  allowedFeatureToggles,
  applicationName,
  handleInputChange,
}) => {
  const notIncludedRoles = [];
  const notIncludedFeatureToggles = [];
  const matchedRoles = [];
  const matchedFeatureToggles = [];

  if (roles[applicationName]) {
    roles[applicationName].forEach((role) => {
      allowedRoles.includes(role) ? matchedRoles.push(role) : notIncludedRoles.push(role);
    });
  }

  if (featureToggles[applicationName]) {
    featureToggles[applicationName].forEach((featureToggle) => {
      allowedFeatureToggles.includes(featureToggle)
        ? matchedFeatureToggles.push(featureToggle)
        : notIncludedFeatureToggles.push(featureToggle);
    });
  }

  return (
    <div style={{ margin: '20px 0' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          item
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid container item spacing={2} direction="row">
            <Grid item xs={12}>
              <Box display="flex">
                <CustomAutoComplete
                  value={matchedRoles}
                  label="Roles"
                  options={allowedRoles}
                  onChange={(_, values) => {
                    handleInputChange({
                      target: {
                        name: 'roles',
                        value: { ...roles, [applicationName]: [...values, ...notIncludedRoles] },
                      },
                    });
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container item spacing={2} direction="row">
            <Grid item xs={12}>
              <Box display="flex">
                <CustomAutoComplete
                  value={matchedFeatureToggles}
                  label="Feature Toggles"
                  options={allowedFeatureToggles}
                  onChange={(_, values) => {
                    handleInputChange({
                      target: {
                        name: 'featureToggles',
                        value: {
                          ...featureToggles,
                          [applicationName]: [...values, ...notIncludedFeatureToggles],
                        },
                      },
                    });
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default UserForm;
