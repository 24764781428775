import { useRef, useState, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import {
  Button,
  Box,
  Divider,
  Avatar,
  Typography,
  IconButton,
  Tooltip,
  MenuItem,
} from '@mui/material';
import { useUserAPI } from '../../context/userAPIContext';
import MenuPopover from './MenuPopover';
import { motion } from 'framer-motion';
import LogoutIcon from '@mui/icons-material/Logout';
import Brightness5Icon from '@mui/icons-material/Brightness5';
import Brightness2Icon from '@mui/icons-material/Brightness2';
import useThemeMode from '../../hooks/useThemeMode';

export const ButtonAnimate = forwardRef(function ButtonAnimate({ children, ...other }, ref) {
  return (
    <Box
      component={motion.div}
      whileTap="tap"
      whileHover="hover"
      variants={{ hover: { scale: 1.05 }, tap: { scale: 0.95 } }}
      sx={{ display: 'inline-flex' }}
    >
      <IconButton ref={ref} {...other}>
        {children}
      </IconButton>
    </Box>
  );
});

export default function AccountPopover() {
  const { onChangeMode, themeMode } = useThemeMode();
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { user, logout } = useUserAPI();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  const ThemeModeItem = () => {
    const isLight = themeMode === 'light';
    const label = `${isLight ? 'Dark' : 'Light'} mode`;
    const Icon = isLight ? Brightness2Icon : Brightness5Icon;
    return (
      <>
        <Icon
          sx={{
            mr: 2,
            width: 24,
            height: 24,
          }}
        />
        {label}
      </>
    );
  };

  return (
    <>
      <Tooltip title="Account Settings">
        <ButtonAnimate
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            padding: 0,
            width: 44,
            height: 44,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
              },
            }),
          }}
        >
          <Avatar src={user?.picture} alt={user?.name} sx={{ width: 48, height: 48 }} />
        </ButtonAnimate>
      </Tooltip>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <MenuItem onClick={onChangeMode} sx={{ typography: 'body1' }}>
          <ThemeModeItem />
        </MenuItem>

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            <LogoutIcon /> Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
