import axios from 'axios';
import EnvManager from '../../config/envManager';

const backend = axios.create({
  baseURL: EnvManager.BACKEND_URL,
  withCredentials: true,
});

const getUserPermissions = async () => {
  try {
    const response = await backend.get('/authz/user-permissions');
    return response?.data;
  } catch (error) {
    return null;
  }
};

export { getUserPermissions };
