import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import authLogo from '../../assets/images/auth-logo.png';

const drawerWidth = 240;

export default function Sidebar(props) {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="drawer options"
      >
        <Drawer
          variant="temporary"
          open={props.open}
          onClose={props.toggleDrawer}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          <Box sx={{ textAlign: 'center', marginTop: 1, marginBottom: 0 }}>
            <img src={authLogo} alt="ioet" width="190rem" height="50rem" />
          </Box>
          <Divider />
          <List>
            {props.listItems.map((item, index) => (
              <ListItem key={index} disablePadding sx={{ display: 'block' }} onClick={item.onClick}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: props.open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      mr: props.open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <item.icon />
                  </ListItemIcon>
                  <ListItemText primary={item.text} sx={{ opacity: props.open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          <Box sx={{ textAlign: 'center', marginTop: 1, marginBottom: 0 }}>
            <img src={authLogo} alt="ioet" width="190rem" height="50rem" />
          </Box>
          <Divider />
          <List>
            {props.listItems.map((item, index) => (
              <ListItem key={index} disablePadding sx={{ display: 'block' }} onClick={item.onClick}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: props.open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      mr: props.open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <item.icon />
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Box>
    </Box>
  );
}
