import React from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import styled from 'styled-components';
import authLogo from '../../assets/images/auth-logo.png';
import { motion } from 'framer-motion';

const StyledBox = styled(Box)`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 7rem;
  width: 13rem;
  cursor: pointer;
  border: black;
`;

export default function Card(props) {
  const redirect = () => {
    window.open(props.url, '_blank');
  };

  const onErrorLoadImage = (e) => {
    e.target.src = authLogo;
    e.target.onerror = null;
  };
  return (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={`Go to ${props.tooltip}`}
      placement="bottom"
    >
      <StyledBox
        component={motion.div}
        whileHover={{
          scale: 1.13,
          transition: { duration: 0.8 },
        }}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1, transition: { duration: 1 } }}
        onClick={redirect}
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          margin: 1,
        }}
      >
        <img
          src={props.source}
          alt={props.name}
          onError={onErrorLoadImage}
          width="160rem"
          height="45rem"
        ></img>
        <div>{props.display_name}</div>
      </StyledBox>
    </Tooltip>
  );
}
