/* eslint-disable camelcase */
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { isAlphanumeric, isURL } from 'validator';
import { CustomModal } from '../common/CustomModal';
import { useForm } from '../../hooks/useForm';
import { ToastAlert } from '../common/ToastAlert';
import { CustomToolbar } from '../common/CustomDataGridToolBar';
import {
  deleteApplication,
  getApplications,
  postApplication,
  updateApplication,
} from '../../services/api/applications';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import ApplicationForm from '../forms/Application';
import { useUserAPI } from '../../context/userAPIContext';
import { customArraySortComparator } from '../../utils/customSorts';
import LinearProgress from '@mui/material/LinearProgress';

const createApplicationModalProps = {
  title: 'Create Application',
  description: 'Please, provide the necessary information to create a new application:',
  requiredIndication: 'Fields marked with an asterisk (*) are required',
  confirmButtonText: 'Create',
  cancelButtonText: 'Cancel',
  confirmButtonColor: 'primary',
  variant: 'outlined',
  confirmButtonIcon: <AddCircleOutlineIcon />,
};

const editApplicationModalProps = {
  title: 'Edit Application',
  description: 'Please, change the attributes that you need:',
  requiredIndication: 'Fields marked with an asterisk (*) are required',
  confirmButtonText: 'Save',
  cancelButtonText: 'Cancel',
  confirmButtonColor: 'primary',
  variant: 'outlined',
  confirmButtonIcon: <SaveIcon />,
};

const deleteApplicationModalProps = (name) => {
  return {
    title: 'Delete Application',
    description: `Are you sure to delete '${name}' application?`,
    confirmButtonText: 'Yes, Delete!',
    cancelButtonText: 'No, cancel!',
    confirmButtonIcon: <DeleteIcon />,
  };
};

const deleteMultipleApplicationModalProps = {
  title: 'Delete Multiple Applications',
  description: 'Are you sure to delete all selected applications?',
  confirmButtonText: 'Yes, Delete!',
  cancelButtonText: 'No, cancel!',
  confirmButtonIcon: <DeleteSweepIcon />,
};

const showAttributes = (attributes) => {
  if (attributes.length > 0) {
    return attributes
      .sort()
      .map((attribute) => <Chip key={attribute} style={{ margin: '2px' }} label={attribute} />);
  }
  return <p>N/A</p>;
};

const ApplicationTabContent = () => {
  const applicationColumns = [
    {
      field: 'display_name',
      headerName: 'Name',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'url',
      headerName: 'Url',
      minWidth: 200,
      flex: 2,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.url} placement="top">
            <a rel="noreferrer" target="_blank" href={params.row.url}>
              {params.row.url}
            </a>
          </Tooltip>
        );
      },
    },
    {
      field: 'allowed_roles',
      headerName: 'Roles',
      minWidth: 180,
      flex: 1,
      filterable: false,
      disableExport: true,
      sortComparator: customArraySortComparator,
      renderCell: (params) => {
        const allowedRoles = params.row.allowed_roles;
        return <div>{showAttributes(allowedRoles)}</div>;
      },
    },
    {
      field: 'default_role',
      headerName: 'Default Role',
      minWidth: 160,
      flex: 1,
      filterable: true,
      disableExport: true,
      renderCell: (params) => {
        const default_role = params.row.default_role ? [params.row.default_role] : [];
        return <div>{showAttributes(default_role)}</div>;
      },
    },
    {
      field: 'allowed_feature_toggles',
      headerName: 'Feature Toggles',
      minWidth: 180,
      flex: 1,
      filterable: false,
      disableExport: true,
      sortComparator: customArraySortComparator,
      renderCell: (params) => {
        const allowedFeatureToggles = params.row.allowed_feature_toggles;
        return <div>{showAttributes(allowedFeatureToggles)}</div>;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 110,
      flex: 1,
      filterable: false,
      sortable: false,
      disableExport: true,
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => {
                const {
                  uuid,
                  name,
                  display_name,
                  default_role,
                  url,
                  icon,
                  allowed_roles,
                  allowed_feature_toggles,
                } = params.row;
                resetForm({
                  uuid,
                  name,
                  displayName: display_name,
                  url,
                  icon,
                  roles: allowed_roles,
                  defaultRole: default_role,
                  feature_toggles: allowed_feature_toggles,
                });
                setOpenEditModal(true);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              color="error"
              onClick={() => {
                setApplicationToDelete(params.row);
                setOpenDeleteModal(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const defaultApplication = {
    name: '',
    displayName: '',
    url: '',
    icon: '',
    defaultRole: '',
    roles: [],
    feature_toggles: [],
  };

  const [formValues, handleInputChange, reset, resetForm] = useForm(defaultApplication);
  const [applications, setApplications] = useState([]);
  const [selectedApplications, setSelectedApplications] = useState([]);
  const [applicationToDelete, setApplicationToDelete] = useState({});
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [openDeleteMultipleModal, setOpenDeleteMultipleModal] = useState(false);
  const [messageToast, setMessageToast] = useState({});
  const [isLoading, setLoading] = useState(true);
  const { checkUserAuthentication } = useUserAPI();

  const fetchApplications = async () => {
    const applications = await getApplications();
    if (!applications) return;
    setApplications(applications);
    setLoading(false);
  };

  useEffect(() => {
    checkUserAuthentication();
    fetchApplications();
    // eslint-disable-next-line
  }, []);

  const createApplication = async () => {
    const result = await postApplication(formValues);
    fetchApplications();
    reset();
    setOpenCreateModal(false);
    setMessageToast(
      result
        ? { message: 'Application successfully created', severity: 'success' }
        : { message: 'Error on creating the Application', severity: 'error' }
    );
    setOpenToast(true);
  };

  const updateSelectedApplication = async () => {
    const result = await updateApplication(formValues);
    reset();
    setOpenEditModal(false);
    setMessageToast(
      result
        ? { message: 'Application successfully updated', severity: 'success' }
        : { message: 'Error on updating the Application', severity: 'error' }
    );
    setOpenToast(true);
    fetchApplications();
  };

  const deleteSelectedApplication = async () => {
    await deleteApplication(applicationToDelete.uuid);
    setOpenDeleteModal(false);
    setMessageToast({ message: 'Application successfully deleted', severity: 'success' });
    setOpenToast(true);
    fetchApplications();
  };

  const deleteMultipleApplications = async () => {
    const applicationPromises = [];

    selectedApplications.forEach((applicationId) => {
      const result = deleteApplication(applicationId);
      applicationPromises.push(result);
    });

    setOpenDeleteMultipleModal(false);

    await Promise.all(applicationPromises);
    setMessageToast({ message: 'Applications successfully deleted', severity: 'success' });
    setOpenToast(true);
    fetchApplications();
  };

  const validateInputFields = () => {
    if (
      isAlphanumeric(formValues.name) &&
      formValues.displayName &&
      formValues.defaultRole &&
      isURL(formValues.url, { require_tld: false }) &&
      isURL(formValues.icon, { require_tld: false })
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p style={{ margin: '10px 0' }}>Hey, here you can manage internal ioet applications.</p>
        {selectedApplications.length > 0 && (
          <Button
            style={{ marginBottom: '10px' }}
            variant="outlined"
            color="error"
            onClick={() => setOpenDeleteMultipleModal(true)}
            startIcon={<DeleteIcon />}
            size="small"
          >
            Delete all selected applications
          </Button>
        )}
      </div>
      <div style={{ height: '60vh', width: '100%' }}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              rows={applications}
              columns={applicationColumns}
              getRowId={(application) => application.uuid}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
              disableSelectionOnClick
              disableColumnResize={false}
              loading={isLoading}
              components={{
                Toolbar: () => (
                  <CustomToolbar
                    addAction={() => {
                      reset();
                      setOpenCreateModal(true);
                    }}
                  />
                ),
                LoadingOverlay: LinearProgress,
              }}
              autoPageSize={true}
              selectionModel={selectedApplications}
              onSelectionModelChange={(newApplicationsSelected) => {
                setSelectedApplications(newApplicationsSelected);
              }}
              getRowHeight={() => 'auto'}
            />
          </div>
        </div>
      </div>

      <CustomModal
        {...deleteMultipleApplicationModalProps}
        open={openDeleteMultipleModal}
        handleClose={() => setOpenDeleteMultipleModal(false)}
        handleConfirm={deleteMultipleApplications}
        handleCancel={() => setOpenDeleteMultipleModal(false)}
      />

      <CustomModal
        {...deleteApplicationModalProps(applicationToDelete.name)}
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        handleConfirm={deleteSelectedApplication}
        handleCancel={() => setOpenDeleteModal(false)}
      />

      <CustomModal
        {...createApplicationModalProps}
        open={openCreateModal}
        handleClose={() => setOpenCreateModal(false)}
        handleConfirm={createApplication}
        handleCancel={() => setOpenCreateModal(false)}
        disabledConfirmButton={!validateInputFields()}
      >
        <ApplicationForm {...formValues} handleInputChange={handleInputChange} />
      </CustomModal>

      <CustomModal
        {...editApplicationModalProps}
        open={openEditModal}
        handleClose={() => setOpenEditModal(false)}
        handleConfirm={updateSelectedApplication}
        handleCancel={() => setOpenEditModal(false)}
        disabledConfirmButton={!validateInputFields()}
      >
        <ApplicationForm {...formValues} handleInputChange={handleInputChange} />
      </CustomModal>

      <ToastAlert
        open={openToast}
        handleClose={() => setOpenToast(false)}
        message={messageToast.message}
        severity={messageToast.severity}
      />
    </>
  );
};

export default ApplicationTabContent;
