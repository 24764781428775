import { getGridStringOperators } from '@mui/x-data-grid';
import { ARRAY_FILTER_OPERATORS } from './constants';

const getFilterOperators = (initialOperators, selectedOperators = []) => {
  if (selectedOperators) {
    const operators = initialOperators.filter((operator) =>
      selectedOperators.includes(operator.value)
    );

    return operators.sort(
      (firstOperator, nextOperator) =>
        selectedOperators.indexOf(firstOperator.value) -
        selectedOperators.indexOf(nextOperator.value)
    );
  }

  return initialOperators;
};

const isEmptyGetApplyFilterFn = () => {
  return (params) => {
    const rowValue = params?.value || [];
    return rowValue.length === 0;
  };
};

const isNotEmptyGetApplyFilterFn = () => {
  return (params) => {
    const rowValue = params?.value || [];
    return rowValue.length > 0;
  };
};

const isAnyOfGetApplyFilterFn = (filterItem) => {
  return (params) => {
    const rowValue = params?.value || [];
    if (!rowValue) return false;
    if (!filterItem?.value?.length) return true;
    const isFilterValueInRowValue = rowValue.some((role) => filterItem?.value?.includes(role));
    return isFilterValueInRowValue;
  };
};

export const customArrayFilterOperators = () => {
  const operators = getFilterOperators(
    getGridStringOperators(),
    Object.values(ARRAY_FILTER_OPERATORS)
  );

  const customGetApplyFiltersFn = {
    [ARRAY_FILTER_OPERATORS.IsEmpty]: isEmptyGetApplyFilterFn,
    [ARRAY_FILTER_OPERATORS.IsAnyOf]: isAnyOfGetApplyFilterFn,
    [ARRAY_FILTER_OPERATORS.IsNotEmpty]: isNotEmptyGetApplyFilterFn,
  };

  return operators.map((operator) => {
    const newOperator = { ...operator };
    newOperator.getApplyFilterFn =
      customGetApplyFiltersFn?.[operator.value] || operator.getApplyFilterFn;
    return newOperator;
  });
};
