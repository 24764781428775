import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';

export const CustomModal = ({
  open,
  handleClose,
  title,
  description,
  requiredIndication,
  confirmButtonText,
  handleConfirm,
  cancelButtonText,
  handleCancel,
  confirmButtonIcon,
  children,
  width = 400,
  cancelButtonColor = 'error',
  confirmButtonColor = 'success',
  disabledConfirmButton = false,
  isLoading = false,
  variant = 'contained',
}) => {
  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        if (isLoading && reason === 'backdropClick') return;
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width,
          bgcolor: 'background.paper',
          borderRadius: '5px',
          boxShadow: 24,
          p: 4,
        }}
      >
        <h3>{title}</h3>
        <Typography id="modal-modal-description" sx={{ mt: 2 }} component="span">
          {description}
          <Typography variant="body2" sx={{ mt: 2 }}>
            {requiredIndication}
          </Typography>
          {children}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: '10px',
            }}
          >
            <Button
              variant={variant}
              onClick={handleCancel}
              color={cancelButtonColor}
              startIcon={<HighlightOffIcon />}
              size="small"
              style={{ marginRight: '5px' }}
            >
              {cancelButtonText}
            </Button>
            <Button
              variant={variant}
              disabled={disabledConfirmButton}
              color={confirmButtonColor}
              onClick={handleConfirm}
              startIcon={confirmButtonIcon}
              size="small"
            >
              {isLoading && <CircularProgress size="1em" />}
              {confirmButtonText}
            </Button>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};
