import React from 'react';
import { motion } from 'framer-motion';
import authLogo from '../../assets/images/auth-logo.svg';
import { Box } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '30vh',
  backgroundColor: theme.palette.background?.default,
}));

//
const LogoImage = styled('img')(() => ({
  width: 64,
  height: 64,
  opacity: 0.25,
  borderRadius: '50%',
}));

const LoadingIndicator = ({ ...props }) => {
  return (
    <RootStyle {...props}>
      <motion.div
        initial={{ rotateY: 0 }}
        animate={{ rotateY: 360 }}
        transition={{
          duration: 0.5,
          ease: 'easeInOut',
          repeatDelay: 1,
          repeat: Infinity,
        }}
      >
        <LogoImage src={authLogo} alt="auth logo" />
      </motion.div>

      <Box
        component={motion.div}
        animate={{
          scale: [1.2, 1, 1, 1.2, 1.2],
          rotate: [270, 0, 0, 270, 270],
          opacity: [0.25, 1, 1, 1, 0.25],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        transition={{ ease: 'easeInOut', duration: 3.2, repeat: Infinity, repeatDelay: 1 }}
        sx={{
          width: 100,
          height: 100,
          borderRadius: '25%',
          position: 'absolute',
          border: (theme) => `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
        }}
      />

      <Box
        component={motion.div}
        animate={{
          scale: [1, 1.2, 1.2, 1, 1],
          rotate: [0, 270, 270, 0, 0],
          opacity: [1, 0.25, 0.25, 0.25, 1],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        transition={{
          ease: 'easeInOut',
          duration: 3.2,
          repeatDelay: 1,
          repeat: Infinity,
        }}
        sx={{
          width: 120,
          height: 120,
          borderRadius: '25%',
          position: 'absolute',
          border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
        }}
      />
    </RootStyle>
  );
};

export default LoadingIndicator;
