import axios from 'axios';
import EnvManager from '../../config/envManager';

const backend = axios.create({
  baseURL: EnvManager.BACKEND_URL,
  withCredentials: true,
});

export const getUsers = async () => {
  try {
    const response = await backend.get('/users/');
    return response?.data;
  } catch (error) {
    return null;
  }
};

export const updateUser = async ({ sub, roles, featureToggles }) => {
  try {
    const baseUserAttributes = {
      sub,
      roles,
      feature_toggles: featureToggles,
    };

    const response = await backend.put('/users/', baseUserAttributes);

    return response?.data;
  } catch (error) {
    /* TODO: Manage auth errors properly (401, 403) */
  }
};
