import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const CustomAutoComplete = ({
  value,
  label,
  placeholder,
  error,
  helperText,
  warning = false,
  onChange,
  options = [],
  isFreeSolo = false,
}) => {
  const textFieldProps = {
    helperText,
    error,
    label,
    placeholder,
    ...(warning && { color: 'warning' }),
  };

  return (
    <Autocomplete
      autoSelect
      value={value}
      onChange={onChange}
      sx={{ width: '100%' }}
      multiple
      id="tags-filled"
      options={options}
      freeSolo={isFreeSolo}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option} key={index} {...getTagProps({ index })} />
        ))
      }
      getOptionLabel={(option) => option}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </li>
      )}
      renderInput={(params) => <TextField {...params} {...textFieldProps} />}
    />
  );
};
