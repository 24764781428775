import { Fab } from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';

import AddIcon from '@mui/icons-material/Add';

export const CustomToolbar = ({ addAction }) => {
  return (
    <GridToolbarContainer>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 10px',
        }}
      >
        <div>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
        </div>
        <div>
          <Fab size="small" color="primary" aria-label="add" onClick={addAction}>
            <AddIcon />
          </Fab>
        </div>
      </div>
    </GridToolbarContainer>
  );
};
