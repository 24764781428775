import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import AuthPageLayout from '../layout/AuthPageLayout';
import CustomTab from '../components/common/CustomTab';
import ApplicationTabContent from '../components/Application/ApplicationTabContent';
import UserTabContent from '../components/User/UserTabContent';
import { useUserAPI } from '../context/userAPIContext';
import { useEffect } from 'react';

export const Admin = () => {
  const adminAvailableTabs = [
    {
      id: 'applications',
      label: 'Applications',
    },
    {
      id: 'users',
      label: 'Users',
    },
  ];

  const tabsContent = [
    {
      id: 1,
      content: <ApplicationTabContent />,
    },
    {
      id: 2,
      content: <UserTabContent />,
    },
  ];

  const { checkUserAuthentication } = useUserAPI();

  useEffect(() => {
    checkUserAuthentication();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <AuthPageLayout containerWidth="lg">
        <Grid container direction="column">
          <Paper
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
            }}
          >
            <CustomTab tabs={adminAvailableTabs} tabsContent={tabsContent} width="100%"></CustomTab>
          </Paper>
        </Grid>
      </AuthPageLayout>
    </>
  );
};
