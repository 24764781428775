import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export const ToastAlert = ({ open, handleClose, message, severity = 'success' }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert onClose={() => {}} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};
