import { createContext, useContext, useEffect, useState } from 'react';
import { getUserPermissions } from '../services/api/authorization';
import { logoutUser } from '../services/api/authentication';

const UserAPIContext = createContext();
const localStorageUserKey = 'user';

export const UserAPIContextProvider = ({ children }) => {
  const [user, setUser] = useState(
    sessionStorage.getItem(localStorageUserKey) !== null
      ? JSON.parse(sessionStorage.getItem(localStorageUserKey))
      : null
  );

  const logout = async () => {
    await logoutUser();
    sessionStorage.removeItem(localStorageUserKey);
  };

  const fetchAndCheckUserPermissions = async () => {
    const user = await getUserPermissions();
    if (user) {
      sessionStorage.setItem(localStorageUserKey, JSON.stringify(user));
    } else {
      sessionStorage.clear();
    }
    setUser(user);
  };

  useEffect(() => {
    fetchAndCheckUserPermissions();
  }, []);

  return (
    <UserAPIContext.Provider
      value={{ user, checkUserAuthentication: fetchAndCheckUserPermissions, logout }}
    >
      {children}
    </UserAPIContext.Provider>
  );
};

export const useUserAPI = () => {
  const context = useContext(UserAPIContext);
  if (!context) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
};
