import { Box } from '@mui/material';
import { CustomAutoComplete } from './CustomAutoComplete';

import Grid from '@mui/material/Grid';

export default function CustomTransfer({
  selectedRoles,
  selectedFeatureToggles,
  allowedFeatureToggles,
  allowedRoles,
  setSelectedRoles,
  setSelectedFeatureToggles,
}) {
  return (
    <>
      <Grid
        marginTop={1}
        container
        item
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid container item spacing={2} direction="row">
          <Grid item xs={12}>
            <Box display="flex">
              <CustomAutoComplete
                value={selectedRoles}
                label="Roles"
                options={allowedRoles}
                onChange={(_, values) => setSelectedRoles(values)}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container item spacing={2} direction="row">
          <Grid item xs={12}>
            <Box display="flex">
              <CustomAutoComplete
                value={selectedFeatureToggles}
                label="Feature Toggles"
                options={allowedFeatureToggles}
                onChange={(_, values) => setSelectedFeatureToggles(values)}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
